







































































































































































import { Vue, Component } from 'vue-property-decorator'
import Spinner from '@/components/misc/Spinner.vue'
import { SaveAccountInput } from '@/store/types'
import router from '@/router'

@Component({
    components: {
        Spinner,
    },
})
export default class CreateAccount extends Vue {
    name: string = ''
    password: string = ''
    cPassword: string = ''
    disableButton: boolean = false
    passwordVisible: boolean = false
    cPasswordVisible: boolean = false
    passError: string = ''
    loading: boolean = false
    error: string | undefined = ''
    validPassword: boolean = false

    togglePasswordVisibility() {
        this.passwordVisible = !this.passwordVisible
    }

    toggleConfirmPasswordVisibility() {
        this.cPasswordVisible = !this.cPasswordVisible
    }

    goBack() {
        router.go(-1)
    }
    checkExistingUsername() {
        this.disableButton = true
        this.password = ''
        this.cPassword = ''
        let existingData = localStorage.getItem('accounts') || null
        const spacePattern = /\s/g
        let nameS = this.name
        if (existingData !== null) {
            let sortedData = JSON.parse(existingData)
            for (var i = 0; i < sortedData.length; i++) {
                if (sortedData[i].name.toLowerCase() == nameS.toLowerCase()) {
                    this.error = '! Username already exists'
                    return
                }
            }
            if (spacePattern.test(nameS)) {
                this.error = '! Space is not allowed'
                return
            }
            if (nameS.length < 6 || nameS.length > 20) {
                this.error = 'Username should contain characters between 6(Minimum) to 20(Maximum)'
                return
            }
        }
        this.error = ''
    }
    verifyPassword() {
        const numberPattern = /^(?=.*[0-9])/
        const loweCasePattern = /^(?=.*[a-z])/
        const upperCasePattern = /^(?=.*[A-Z])/
        const specialCharacterPattern = /^(?=.*[`~!@#$%^&*()])/
        const spacePattern = /\s/g
        this.validPassword = false
        this.disableButton = true
        this.cPassword = ''
        if (!this.password) {
            this.passError = 'New Password is required'
        } else if (typeof this.password !== 'string') {
            this.passError = 'New Password is not String'
        } else if (
            !(
                numberPattern.test(this.password) &&
                loweCasePattern.test(this.password) &&
                upperCasePattern.test(this.password) &&
                specialCharacterPattern.test(this.password)
            )
        ) {
            this.passError =
                '! At least contain one number, one lower case alphabet and one upper case alphabet and one special character'
        } else if (!specialCharacterPattern.test(this.password)) {
            this.passError = '! At least contain one of these `~!@#$%^&*() special character only'
        } else if (spacePattern.test(this.password)) {
            this.passError = '! Space is not allowed'
        } else if (this.password.length < 8) {
            this.passError = '! Minimum 8 characters are required'
        } else if (this.password.length > 64) {
            this.passError = '! Maximum 64 characters are allowed'
        } else {
            this.passError = ''
            this.validPassword = true
        }
    }

    verifyCpassword() {
        this.disableButton = true
        if (!this.cPassword) {
            this.passError = '! Confirm Password is required'
        } else if (typeof this.cPassword !== 'string') {
            this.passError = '! Confirm Password is not String'
        } else if (this.password !== this.cPassword) {
            this.passError = "! Confirm Password doesn't match"
        } else {
            this.passError = ''
            this.disableButton = false
        }
    }

    checkForError() {
        if (!this.password) return this.$t('keys.password_validation').toString()
        if (!this.cPassword) return this.$t('keys.password_validation2').toString()
        if (this.name.length < 1) return this.$t('keys.account_name_required').toString()
        if (this.password !== this.cPassword) return this.$t('keys.password_validation2').toString()
    }

    access() {
        this.disableButton = true
        this.loading = true
        this.error = this.checkForError()
        let input: SaveAccountInput = {
            accountName: this.name,
            password: this.password,
        }
        this.$emit('success')
        //temp fix
        setTimeout(async () => {
            await this.$store.dispatch('Accounts/saveAccount', input)
        }, 2000)
    }

    checkPassword() {}
}
