





















































































































































































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'
import TextDisplayCopy from '@/components/misc/TextDisplayCopy.vue'
import Spinner from '@/components/misc/Spinner.vue'
// import TorusGoogle from "@/components/Torus/TorusGoogle.vue";
import MnemonicDisplay from '@/components/misc/MnemonicDisplay.vue'
import CopyText from '@/components/misc/CopyText.vue'
import * as bip39 from 'bip39'

import VerifyMnemonic from '@/components/modals/VerifyMnemonic.vue'
import MnemonicCopied from '@/components/CreateWalletWorkflow/MnemonicCopied.vue'
import ToS from '@/components/misc/ToS.vue'
import MnemonicPhrase from '@/js/wallets/MnemonicPhrase'
import CreateAccount from '@/components/CreateWalletWorkflow/CreateAccount.vue'

import jsPDF from 'jspdf'

@Component({
    components: {
        ToS,
        CopyText,
        // RememberKey,
        TextDisplayCopy,
        MnemonicDisplay,
        Spinner,
        // TorusGoogle,
        VerifyMnemonic,
        MnemonicCopied,
        CreateAccount,
    },
})
export default class CreateWallet extends Vue {
    // TODO: We do not need to create keyPair, only mnemonic is sufficient
    isLoad: boolean = false
    keyPhrase: MnemonicPhrase | null = null
    isSecured: boolean = false
    verifyMnemonic: boolean = false
    isChecked: boolean = false
    canCreateAccount: boolean = false

    get canVerify(): boolean {
        return this.isSecured ? true : false
    }

    get verificationColor() {
        return this.verifyMnemonic ? '#a9efbf' : '#F5F6FA'
    }

    createKey(): void {
        this.isSecured = false
        let mnemonic = bip39.generateMnemonic(256)
        this.keyPhrase = new MnemonicPhrase(mnemonic)
    }

    get canSubmit(): boolean {
        return true
    }

    toggleVerifyMnemonic() {
        this.verifyMnemonic = !this.verifyMnemonic
    }

    complete() {
        this.canCreateAccount = !this.canCreateAccount
    }

    copy() {
        navigator.clipboard.writeText(this.keyPhrase?.getValue() || '').then(() => {
            this.$store.dispatch('Notifications/add', {
                title: 'Copy',
                message: 'Copied to clipboard.',
            })
        })
    }

    download() {
        //TODO
        // style the document
        const _doc = new jsPDF('l', 'px', 'a4')

        const _rootHTML = document.createElement('div')

        const _container = document.createElement('div')
        _container.className = 'container'

        const _heading = document.createElement('h2')
        _heading.innerText = 'Mnemonic Phrase'
        _heading.style.margin = 'auto'

        const phrase = this.keyPhrase?.getValue().trim().split(' ')
        const phraseLength = phrase?.length || 0

        const _phraseContainer = document.createElement('div')

        for (let i = 0; i < phraseLength; ++i) {
            const row = document.createElement('div')
            for (let r = 0; r < 6; ++r) {
                const key = document.createElement('span')
                key.innerText = `    ${i + r + 1} ${phrase ? phrase[i + r] : ''}    `
                key.style.margin = '50px'
                row.appendChild(key)
            }
            _phraseContainer.appendChild(row)
            i += 5
        }
        _phraseContainer.style.display = 'grid'
        _phraseContainer.style.alignItems = 'center'
        _container.appendChild(_heading)
        _container.appendChild(_phraseContainer)
        _rootHTML.appendChild(_container)
        //_doc.text(`phrase: ${this.keyPhrase?.getValue()}`, 10, 10)
        _doc.fromHTML(_rootHTML, 10, 10)
        _doc.save('dcomm_phrase.pdf')
    }

    handleChecked() {
        this.isChecked = !this.isChecked
    }

    async access(): Promise<void> {
        if (!this.keyPhrase) return

        this.isLoad = true

        let parent = this

        await parent.$store.dispatch('accessWallet', this.keyPhrase!.getValue())
    }
}
