





















import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

import NetworkRow from './NetworkRow.vue'
import { DcommNetwork } from '@/js/DcommNetwork'
import ConfigNetwork from './ConfigNetwork.vue'
@Component({
    components: {
        NetworkRow,
        ConfigNetwork,
    },
})
export default class NetworkList extends Vue {
    showCustom: boolean = false
    get networks(): DcommNetwork[] {
        return this.$store.getters['Network/allNetworks']
    }

    onEdit(net: DcommNetwork) {
        this.$emit('edit', net)
    }

    onAddNetwork() {
        this.$emit('showcustom')
        // this.showCustom = true
    }
}
