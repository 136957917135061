import { KeyChain as DVMKeyChain, DVMAPI } from '@dcomm-tech/dcomm-js/dist/apis/dvm'
import { InfoAPI } from '@dcomm-tech/dcomm-js/dist/apis/info'
import Dcomm from '@dcomm-tech/dcomm-js'
//@ts-ignore
import BinTools from '@dcomm-tech/dcomm-js/dist/utils/bintools'
import { EVMAPI } from '@dcomm-tech/dcomm-js/dist/apis/evm'

// Connect to TestNet by default
// Doesn't really matter how we initialize, it will get changed by the network module later
const ip: string = '54.197.159.85'
const port: number = 9651
const protocol: string = 'http'
const network_id: number = 5
const chain_id: string = 'AST'
const bintools: BinTools = BinTools.getInstance()
const dcomm: Dcomm = new Dcomm(ip, port, protocol, network_id, chain_id)

const dvm: DVMAPI = dcomm.ASTChain()
const actChain: EVMAPI = dcomm.ACTChain()
const athChain = dcomm.ATHChain()
const infoApi: InfoAPI = dcomm.Info()
const keyChain: DVMKeyChain = dvm.keyChain()

function isValidAddress(addr: string) {
    try {
        const res = bintools.stringToAddress(addr)
        return true
    } catch (err) {
        return false
    }
}

export { dcomm, dvm, athChain, actChain, infoApi, bintools, isValidAddress, keyChain }
