




































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

import NetworkRow from './NetworkRow.vue'
import ConfigNetwork from './ConfigNetwork.vue'
import NetworkList from './NetworkList.vue'
import { DcommNetwork } from '@/js/DcommNetwork'
import { NetworkStatus } from '@/store/modules/network/types'
import { activeNetwork } from '@dcomm-tech/wallet-sdk'
import EditNetwork from './EditNetwork.vue'

@Component({
    components: {
        NetworkList,
        NetworkRow,
        ConfigNetwork,
        EditNetwork,
    },
})
export default class NetworkMenu extends Vue {
    page: string = 'list'
    isActive: boolean = false
    editNetwork: DcommNetwork | null = null
    isEditNetwork: boolean = false
    editNetworkTitle: string = ''
    showCustom: boolean = false
    showEditModal: boolean = false
    viewCustom(): void {
        this.page = 'custom'
    }
    viewList(): void {
        this.page = 'list'
    }
    closeMenu(): void {
        this.page = 'list'
        this.isActive = false
    }
    toggleMenu(): void {
        this.isActive = !this.isActive
    }

    toggleConfigNetwork() {
        this.isEditNetwork = !this.isEditNetwork
    }

    saveNetworkConfig(_net: DcommNetwork) {}

    addCustomNetwork(data: DcommNetwork): void {
        this.$store.dispatch('Network/addCustomNetwork', data)
    }

    showCustomMenu() {
        this.showCustom = !this.showCustom
    }

    networkUpdated() {
        this.page = 'list'
        this.$store.dispatch('Network/save')
    }
    showEditMenu() {
        this.showEditModal = !this.showEditModal
    }
    onEdit(network: DcommNetwork): void {
        this.editNetwork = network
        this.showEditModal = true
    }

    get status(): NetworkStatus {
        return this.$store.state.Network.status
    }
    get activeNetwork(): null | DcommNetwork {
        return this.$store.state.Network.selectedNetwork
    }
    get networks(): DcommNetwork[] {
        return this.$store.getters('Network/allNetworks')
        // return this.$store.state.Network.networks;
    }

    get isTestnet(): boolean {
        let net = this.activeNetwork

        if (!net) return false
        if (net.networkId !== 1) return true
        return false
    }
}
